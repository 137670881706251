import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { ToastContainerComponent } from 'app/modules/admin/toastr/toast-container/toast-container.component';
import { AuthGuard } from './guards/auth.guard';
import { PusherService } from './modules/admin/services/pusher.service';
import { CurrencyCommasHundredsPipe } from './modules/admin/currency-commas-hundreds.pipe';


const appRoutes: Routes = [
  // {
  //   path: 'pages',
  //   loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  // },
  /*{
    path: 'login',
    loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'inmobiliarias',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/admin/inmobiliarias/inmobiliarias.module').then(m => m.InmobiliariasModule)
  },
  {
    path: 'asesores',
    loadChildren: () => import('./modules/admin/agentes/agentes.module').then(m => m.AgentesModule)
  },
  {
    path: 'directorio',
    loadChildren: () => import('./modules/admin/directory/directory.module').then(m => m.DirectoryModule)
  },
  {
    path: 'asociaciones',
    loadChildren: () => import('./modules/admin/partnerships/partnership.module').then(m => m.PartnershipModule)
  },
  {
    path: 'clientes',
    loadChildren: () => import('./modules/admin/clients/clients.module').then(m => m.ClientsModule)
  },
  {
    path: 'utilidades',
    loadChildren: () => import('./modules/admin/utilidades/utilidades.module').then(m => m.UtilidadesModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./modules/admin/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'marketing',
    loadChildren: () => import('./modules/admin/marketing/marketing.module').then(m => m.MarketingModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./modules/admin/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/admin/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'settings/faqs',
    loadChildren: () => import('./modules/admin/setting/faqs/faqs.module').then(m => m.FaqsModule)
  },
  {
    path: 'properties',
    loadChildren: () => import('./modules/admin/properties/property.module').then(m => m.PropertyModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
  },*/
  {
    path: 'frames',
    loadChildren: () => import('./frames/frames.module').then(m => m.FramesModule)
  },
  {
    path: '',
    loadChildren: () => import('./main/pages/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '**',
    redirectTo: '' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent, ToastContainerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule
  ],

  bootstrap: [AppComponent],

  providers: [
    PusherService
  ]
})
export class AppModule { }
