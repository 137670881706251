import { Role } from './role';
import { Permission } from '../../modules/admin/users/permission.model';
import { Consultant } from '../../modules/admin/agentes/consultant.model';

export class User {
  id: number;
  email: string;
  phone: string;
  password: string;
  pass?: string;
  firstName: string;
  lastName1: string;
  lastName2: string;
  fullName: string;
  avatar: string;
  role?: Role;
  token?: string;
  permissions?: Permission[];
  realEstate_id?: number;
  agentId?: number;
  state?: number;
  province?: number;
  admin?: boolean;
  isAgent?: boolean;
  Agent?: Consultant;
  isWhatsApp?: boolean;
}