/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private _pusher: any;
  constructor() {
    this._pusher = new Pusher('8b133b96c44a51407648', {
      cluster: 'us2',
      forceTLS: true
    });
  }
  // any time it is needed we simply call this method
  getPusher(): any {
    return this._pusher;
  }
}
