import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(private http: HttpClient,) { }

  getServer(url: string, params?) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.API_URL_LANDING + url, { params: params }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );
    });
  }

  postServer(url: string, params: any) {
    console.log(params);
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL_LANDING + url, params).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );
    });
  }

}
