import { Injectable } from '@angular/core';
import { ServerService } from '../server.service';
import { Inmobiliaria, RealEstatePhones, Meet } from '../../../modules/admin/inmobiliarias/inmobiliaria.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private _server: ServerService) { }

  registerRealEstate(realEstate: Inmobiliaria): Promise<Inmobiliaria> {
    return new Promise((resolve, reject) => {
      this._server.postServer('Register', realEstate).then(
        (resp: Inmobiliaria) => {

          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  getStates(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('states').then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getMeets(): Promise<Meet[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Meet').then(
        (resp: Meet[]) => {
          resolve(resp);
        }, (err: any) => {
          reject();
        }
      );
    });
  }

  getProvinceById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('provinces/' + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  recoverPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('sendPass', { email: email }).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }


  realEstateSubscriptions(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getSubscriptionsRealEstate/' + id).then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }
}
