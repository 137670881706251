import { Injectable } from "@angular/core";
import { Property, PropertyType } from "app/modules/admin/properties/property.model";
import { ServerService } from "../server.service";
import { Search } from "./search.model";
import { Amenities } from "../../../modules/admin/properties/property.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LandingService {
  private property: Property[];
  private sharingObject: Search;
  constructor(private _server: ServerService) {}

  getConfig(data: any) {
    return new Promise((resolve, reject) => {
      this._server.postServer("getConfig", data).then(
        (resp: any) => {
          console.log("getConfig RESP landing.service.ts", resp);
          resolve(resp);
        },
        (err: any) => {
          console.log("getConfig ERROR landing.service.ts", err);
          reject(err);
        }
      );
    });
  }

  getLicenses(data: any) {
    return new Promise((resolve, reject) => {
      this._server.getServer("RealEstateLicenses", data).then(
        (resp: any) => {
          console.log("getLicenses RESP landing.service.ts", resp);
          resolve(resp);
        },
        (err: any) => {
          console.log("getLicenses ERROR landing.service.ts", err);
          reject(err);
        }
      );
    });
  }

  allProperties(idUs: number = null, data: any): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      const cadConexion = idUs //Aquí el indexWithAgent devuelve todo sin tomar en cuenta inmobiliaria
        ? "indexWithAgent/" + idUs
        : "micrositioProperties";

      if (!idUs) {
        this._server.postServer(cadConexion, data).then(
          (resp: Property[]) => {
            this.property = resp;
            resolve(resp);
          },
          (err: any) => {
            reject(err);
          }
        );
      } else {
        this._server.getServer(cadConexion, data).then(
          (resp: Property[]) => {
            this.property = resp;
            resolve(resp);
          },
          (err: any) => {
            reject(err);
          }
        );
      }
    });
  }

  allPropertiesWithFilter(idUs: number = null, data: any): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      const cadConexion = idUs ? "indexWithAgent/" + idUs : "micrositioPropertiesWithFilter"; //

      if (!idUs) {
        this._server.postServer(cadConexion, data).then(
          (resp: Property[]) => {
            this.property = resp;
            resolve(resp);
          },
          (err: any) => {
            reject(err);
          }
        );
      } else {
        this._server.getServer(cadConexion, data).then(
          (resp: Property[]) => {
            this.property = resp;
            resolve(resp);
          },
          (err: any) => {
            reject(err);
          }
        );
      }
    });
  }

  allAmenities(): Promise<Amenities[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("amenities").then(
        (resp: Amenities[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  private _sharingValue = new BehaviorSubject<any>(this.getInitialValue());
  sharingValue$ = this._sharingValue.asObservable();
  getInitialValue() {
    return JSON.parse(localStorage.getItem("busqueda"));
  }
  get sharingValue() {
    this.sharingObject = JSON.parse(localStorage.getItem("busqueda"));
    return this.sharingObject;
  }

  set sharingValue(obj) {
    this.sharingObject = obj;
    this._sharingValue.next(obj);
    localStorage.setItem("busqueda", JSON.stringify(this.sharingObject));
  }

  allPropertiesTypes(): Promise<PropertyType[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("propertyTypes").then(
        (resp: PropertyType[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  allPropertiesSearchResults(params: any, idUser: number = null): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      const urlSend = idUser ? "PropertiesWithFilterWithAgent/" + idUser : "PropertiesWithFilter";
      // const urlSend = idUser ? 'PropertiesWithFilterWithAgent/' + idUser : 'micrositioPropertiesWithFilter';

      this._server.postServer(urlSend, params).then(
        (resp: Property[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getPropertyId(id: number, idUser: number = null): Promise<Property> {
    const cadConexion = idUser ? "showWithAgent/" + id + "/" + idUser : "Properties/" + id;

    return new Promise((resolve, reject) => {
      this._server.getServer(cadConexion).then(
        (resp: Property) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getStates(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("states").then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getProvinceById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("provinces/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getValuesAssessment(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("valuesAssessment").then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getSuburbsById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("suburbs/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getDigitalCard(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("digitalCard/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getClientNoLogin(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("showClientsLanding/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  getClientPropertiesProperties(params: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("indexClientProperty", params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  contactoRealEstate(data: any) {
    return new Promise((resolve, reject) => {
      this._server.postServer("contactoRealEstate", data).then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }
}
