import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OneSignal } from 'onesignal-ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServerService } from '../../../../modules/admin/services/server.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private serverService: ServerService,
    private _oneSignal: OneSignal
  ) {
    this.onApiDataChange = new BehaviorSubject('');


    // this._howl.register('notif', {
    //   src: ['assets/sounds/notificacion.wav'],
    //   html5: true
    // }).subscribe((status: any) => {
    //   console.log('register status');
    //   console.log(status);
    // });
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.serverService.getServer('indexUser').then((response: any) => {
        this.apiData = response;
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }

  getOneSignal(): Promise<any> {
    return new Promise((resolve, reject) => {

      this._oneSignal.init({
        appId: 'bfaddab4-33f6-4f10-a79e-5c139ca15346',
      }).then((data: any) => {
        this._oneSignal.getUserId().then((userId: any) => {
          localStorage.setItem('onesignal_id', userId)
          // En el login se debe mandar el onesignal_id para que se vincule con el usuario. 
          this.getNotificationsData();
          resolve('');
        }, (error) => {
          reject();
        });
      });
    });

  }

}
