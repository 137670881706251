import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/menu/menu';
import { menuInmo } from 'app/menu/menuInmo';
import { menuAgent } from 'app/menu/menuAgent';
import { locale as menuEnglish } from 'app/menu/i18n/en';
import { locale as menuFrench } from 'app/menu/i18n/fr';
import { locale as menuGerman } from 'app/menu/i18n/de';
import { locale as menuPortuguese } from 'app/menu/i18n/pt';
import { locale as menuSpanish } from 'app/menu/i18n/es';
import { ServerService } from './modules/admin/services/server.service';
import { CoreMenu } from '@core/types'
import { User } from './auth/models';
import { RegisterService } from './main/pages/authentication/register.service';
import { LandingService } from './main/pages/landing/landing.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  defaultLanguage: 'es'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'es'; // Set application default language i.e fr
  public currentUser: User;

  public contentHeader: object;
  public hideValue: boolean = true;
  public toastStyle: object = {};

  menuP: CoreMenu[];
  mostrarEstadisticas: boolean = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private server: ServerService,
    private _landingService: LandingService,
    private _registerService: RegisterService,
  ) {
    var host = window.location.host

    console.log('AQUI CERO', host);
    // Get the application main menu
    //this.menu = menu;// Original
    const domain = host.split('.')[1];
    let data;
    if (domain == 'in-mo' || domain == 'inmoapp') {
      data = {
        domain: host.split('.')[0],
      }
    } else {
      data = {
        domain: environment.DOMAIN
      }
    }
    console.log('AQUI UNO', domain, data);
    this._landingService.getConfig(data).then(
      (data: any) => {
        console.log('AQUI yes', data);
      }, (error: any) => {
        console.log('AQUI error', error);
      }
    );

    this.asignOptionMenu();
    this.menu = this.menuP;

    this._coreMenuService.unregister('main');

    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'fr', 'de', 'pt', 'es']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('es');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese, menuSpanish);

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    server.getToken();
  }


  asignOptionMenu() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))?.user;

    if (this.currentUser) {
      if (this.currentUser.role !== 'Agente') {
        this._registerService.realEstateSubscriptions(this.currentUser.realEstate_id).then(
          (data: any) => {

            if (data.statics) {
              this.mostrarEstadisticas = true;
            }
          }, (error: any) => {
            console.log(error);
          }
        );
      }

      const roles = this.currentUser['role'] === 'Agente' ? 'Inmobiliaria' : this.currentUser['role'];

      switch (roles) {
        case 'Super Administrador':
          let menuPermission = [];
          let permission = null;
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver dashboard');
          if (permission) {
            menuPermission.push({
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'trending-up',
              url: '/dashboard'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver inmobiliarias');
          if (permission) {
            menuPermission.push({
              id: 'inmobiliarias',
              title: 'Inmobiliarias',
              type: 'item',
              icon: 'book-open',
              url: '/inmobiliarias'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permission) {
            menuPermission.push({
              id: 'inmuebles',
              title: 'Inmuebles',
              type: 'item',
              icon: 'home',
              url: '/properties'
            });
          }
          // permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asesores');
          // if (permission) {
          //   menuPermission.push({
          //     id: 'asesores',
          //     title: 'Asesores',
          //     type: 'item',
          //     icon: 'briefcase',
          //     url: 'asesores/'
          //   });
          // }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permission) {
            let child = [{
              id: 'leads',
              title: 'Clientes',
              type: 'item',
              icon: 'circle',
              url: '/clientes/leads'
            }];
            permission = this.currentUser.permissions.find(permi => permi.name === 'Ver estadísticas de ventas');
            if (permission) {
              child.push({
                id: 'statistics',
                title: 'Estadísticas',
                type: 'item',
                icon: 'circle',
                url: '/clientes/statistics'
              });
            }
            menuPermission.push({
              id: 'clientes',
              title: 'Clientes',
              type: 'collapsible',
              icon: 'users',
              children: child
            });
          }

          permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permission) {
            menuPermission.push({
              id: 'subscription',
              title: 'Suscripciones',
              type: 'item',
              icon: 'dollar-sign',
              url: '/subscriptions'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver notificaciones');
          if (permission) {
            menuPermission.push({
              id: 'notification',
              title: 'Notificaciones',
              type: 'item',
              icon: 'bell',
              url: '/notifications'
            });
          }

          permission = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permission) {
            let child = [
              {
                id: 'redes',
                title: 'Redes Sociales',
                type: 'item',
                icon: 'circle',
                url: 'marketing/redessociales'
              }];
            menuPermission.push({
              id: 'marketing',
              title: 'Marketing',
              type: 'collapsible',
              icon: 'shopping-bag',
              children: child
            });
          }
          let child = [];
          permission = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración inmueble');
          if (permission) {
            child.push({
              id: 'valuacion',
              title: 'Valuación',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracioninmueble'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver directorio');
          if (permission) {
            child.push({
              id: 'directorio',
              title: 'Directorio',
              type: 'item',
              icon: 'circle',
              url: '/directorio'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración hipoteca');
          if (permission) {
            child.push({
              id: 'hipoteca',
              title: 'Hipoteca',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracionhipoteca'
            });
          }

          if (child.length != 0) {
            menuPermission.push({
              id: 'utilidades',
              title: 'Utilidades',
              type: 'collapsible',
              icon: 'box',
              children: child
            });
          }

          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver FAQS');
          if (permission) {
            let child = [
              {
                id: 'faqs',
                title: 'FAQs',
                type: 'item',
                icon: 'help-circle',
                url: '/settings/faqs'
              }];
            menuPermission.push({
              id: 'settings',
              title: 'Configuraciones',
              type: 'collapsible',
              icon: 'settings',
              children: child
            });
          }

          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asociaciones');
          if (permission) {
            menuPermission.push({
              id: 'asociaciones',
              title: 'Asociaciones',
              type: 'item',
              icon: 'book',
              url: '/asociaciones'
            });
          }
          let childUser = [];
          childUser.push({
            id: 'miPerfil',
            title: 'Cuenta',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/perfil'
          });
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver Mi Inmobiliaria');
          if (permission) {
            childUser.push({
              id: 'miInmobiliaria',
              title: 'Mi Inmobiliaria',
              type: 'item',
              icon: 'circle',
              url: 'usuarios/miInmobiliaria'
            });
          }
          childUser.push({
            id: 'tarjetaDigital',
            title: 'Tarjeta Digital',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/tarjetaDigital'
          });

          menuPermission.push({
            id: 'perfil',
            title: 'Cuenta',
            type: 'collapsible',
            icon: 'user',
            children: childUser
          });
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver usuarios');
          if (permission) {
            menuPermission.push({
              id: 'usuarios',
              title: 'Usuarios',
              type: 'item',
              icon: 'users',
              url: 'usuarios/usuarios'
            });
          }
          this.menuP = menuPermission
          break;
        case 'Inmobiliaria':
          let menuPermissionInmo = [];
          let permissionInmo = null;
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver dashboard');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'trending-up',
              url: '/dashboard'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'inmuebles',
              title: 'Inmuebles',
              type: 'item',
              icon: 'home',
              url: '/properties'
            });
          }
          // permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver asesores');
          // if (permissionInmo) {
          //   menuPermissionInmo.push({
          //     id: 'asesores',
          //     title: 'Asesores',
          //     type: 'item',
          //     icon: 'briefcase',
          //     url: 'asesores/'
          //   });
          // }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permissionInmo) {
            let childInmo = [
              {
                id: 'leads',
                title: 'Clientes',
                type: 'item',
                icon: 'circle',
                url: '/clientes/leads'
              }];
            // permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver estadísticas de ventas');
            if (this.mostrarEstadisticas) {
              childInmo.push({
                id: 'statistics',
                title: 'Estadísticas',
                type: 'item',
                icon: 'circle',
                url: '/clientes/statistics'
              });
            }
            menuPermissionInmo.push({
              id: 'clientes',
              title: 'Clientes',
              type: 'collapsible',
              icon: 'users',
              children: childInmo
            });
          }

          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'subscription',
              title: 'Suscripciones',
              type: 'item',
              icon: 'dollar-sign',
              url: '/subscriptions'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver notificaciones');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'notification',
              title: 'Notificaciones',
              type: 'item',
              icon: 'bell',
              url: '/notifications'
            });
          }

          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permissionInmo) {
            let childInmo = [
              {
                id: 'redes',
                title: 'Redes Sociales',
                type: 'item',
                icon: 'circle',
                url: 'marketing/redessociales'
              }];
            menuPermissionInmo.push({
              id: 'marketing',
              title: 'Marketing',
              type: 'collapsible',
              icon: 'shopping-bag',
              children: childInmo
            });
          }
          let childInmo = [];
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración inmueble');
          if (permissionInmo) {
            childInmo.push({
              id: 'valuacion',
              title: 'Valuación',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracioninmueble'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver directorio');
          if (permissionInmo) {
            childInmo.push({
              id: 'directorio',
              title: 'Directorio',
              type: 'item',
              icon: 'circle',
              url: '/directorio'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración hipoteca');
          if (permissionInmo) {
            childInmo.push({
              id: 'hipoteca',
              title: 'Hipoteca',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracionhipoteca'
            });
          }

          if (childInmo.length != 0) {
            menuPermissionInmo.push({
              id: 'utilidades',
              title: 'Utilidades',
              type: 'collapsible',
              icon: 'box',
              children: childInmo
            });
          }
          let childUserInmo = [];
          childUserInmo.push({
            id: 'miPerfil',
            title: 'Perfil',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/perfil'
          });
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver Mi Inmobiliaria');
          if (permissionInmo) {
            childUserInmo.push({
              id: 'miInmobiliaria',
              title: 'Mi Inmobiliaria',
              type: 'item',
              icon: 'circle',
              url: 'usuarios/miInmobiliaria'
            });
          }
          childUserInmo.push({
            id: 'tarjetaDigital',
            title: 'Tarjeta Digital',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/tarjetaDigital'
          });

          menuPermissionInmo.push({
            id: 'perfil',
            title: 'Perfil',
            type: 'collapsible',
            icon: 'user',
            children: childUserInmo
          });

          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver usuarios');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'usuarios',
              title: 'Usuarios',
              type: 'item',
              icon: 'users',
              url: 'usuarios/usuarios'
            });
          }
          this.menuP = menuPermissionInmo

          break;

      }
    }



  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Init wave effect (Ripple effect)
    Waves.init();

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;

      // Set application default language.

      // Change application language? Read the ngxTranslate Fix

      // ? Use app-config.ts file to set default language
      const appLanguage = this.coreConfig.app.appLanguage || 'es';
      this._translateService.use(appLanguage);

      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/

      // Set the default language to 'en' and then back to 'fr'.

      setTimeout(() => {
        this._translateService.setDefaultLang('es');
        this._translateService.setDefaultLang(appLanguage);
      });

      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }
}
