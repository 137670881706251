/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  hmr: false,
  API_URL: 'https://back.in-mo.app/api/admin/',
  API_URL_LANDING: 'https://back.in-mo.app/api/landing/',
  URL: 'https://back.in-mo.app',
  URL_LANDING: 'https://in-mo.app/',
  URL_SUBDOMAIN_LANDING: '.in-mo.app',
  URL_AWS: 'https://inmoapp-storage.s3.us-west-1.amazonaws.com/',
  MAPS_API_KEY: 'AIzaSyCX2xPTQp5j8T3pla2GSzRuYTi8z_QVEME',
  PUBLIC_KEY_MP: 'TEST-e14b6667-bac3-427a-a24d-49fc7cfcb714',
  ACCESS_TOKEN_MP: 'TEST-8824949066921584-111814-c2d50104e475719538bc0c8efa9bdb31-453316830'
};
