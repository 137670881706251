import { Component, TemplateRef } from '@angular/core';

import { MyToastrService } from '../../services/my-toastr.service';
@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastContainerComponent {
  time: string = 'Hace 11 min.'
  constructor(public toastService: MyToastrService) { }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
