import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { MyToastrService } from 'app/modules/admin/services/my-toastr.service';
import { ServerService } from '../../modules/admin/services/server.service';
import { PusherService } from '../../modules/admin/services/pusher.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: MyToastrService,
    private _server: ServerService,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is Admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value['user']?.role === Role.Admin;
  }

  /**
   *  Confirms if user is Inmob
   */
  get isInmob() {
    return this.currentUser && this.currentUserSubject.value['user']?.role === Role.Inmob;
  }

  /**
   *  Confirms if user is Agent
   */
  get isAgent() {
    return this.currentUser && this.currentUserSubject.value['user']?.role === Role.Agent;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.API_URL}login`, { email, password })
      .pipe(
        map(user => {

          // login successful if there's a jwt token in the response
          if (user && user.token != '') {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            localStorage.setItem('accessToken', JSON.parse(localStorage.getItem('currentUser'))?.user.token);


            this._server.getToken();

            // Display welcome toast!
            this._toastrService.show('Bienvenido', 'Acceso concedido');

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
