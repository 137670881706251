export const locale = {
  lang: 'es',
  data: {
    MENU: {
      HOME: 'Dashboard',
      SAMPLE: 'Sample',
      CLIENTS: 'Clientes',
      USERS: 'Usuarios',
      COMPANIES: 'Empresas',
    } 
  }
}
